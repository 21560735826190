import React from 'react';
import { stockCategoryDictionary } from 'common/stockCategoryDictionary';
import { stockTypeDictionary } from 'common/stockTypeDictionary';
import { ReservationChip } from './ReservationChip';
import { RadioGroup } from 'components/radioGroup/RadioGroup.component';
import { Button } from 'components/button/Button.component';
import { WarningIcon } from 'components/warningIcon/WarningIcon.component';
import uuid from 'react-uuid';
import { useNewOfferFromStockApi } from 'pages/newOfferFromStock/useNewOfferFromStockApi';
import ModelDictionary from 'common/modelDictionary';
import { useFormattedCurrency } from 'utils/formatCurrency';
import { useStatus } from 'common/statusContext';
export function StockCarsListBodyRow(_a) {
    var stockCar = _a.stockCar, selectedCar = _a.selectedCar, handleOnRowClick = _a.handleOnRowClick, _b = _a.searchingMode, searchingMode = _b === void 0 ? false : _b, handleSetAppError = _a.handleSetAppError;
    var newOfferFromStockApi = useNewOfferFromStockApi();
    var _c = useStatus(), status = _c.status, setStatus = _c.setStatus;
    var isSelected = selectedCar === stockCar.commonOrderNumber;
    function handleOnSelectStockCar() {
        var offerId = uuid();
        var fyon = stockCar.fyon;
        var _a = window.location.href.split('?'), searchParamsString = _a[1];
        var searchParams = new URLSearchParams(searchParamsString);
        var clientId = searchParams.get('clientId');
        var partyId = searchParams.has('partyId') ? searchParams.get('partyId') : null;
        var context = searchParams.get('context');
        var modelYear = stockCar.modelYear;
        var factoryOrderDate = stockCar.factoryOrderDate;
        var commonOrderNumber = stockCar.commonOrderNumber;
        var modelId = stockCar.modelId;
        var carOnLineDate = stockCar.carOnLineDate;
        var structureWeek = stockCar.structureWeek;
        var fixedPlannedDate = stockCar.fixedPlannedDate ? stockCar.fixedPlannedDate : null;
        setStatus('pending');
        newOfferFromStockApi.checkCar(offerId, fyon, clientId, partyId, modelYear, factoryOrderDate, commonOrderNumber, fixedPlannedDate)
            .then(function () {
            handleSetAppError(undefined);
            setStatus('success');
            // przejście do nowego kreatora
            var newSearchParams = new URLSearchParams();
            newSearchParams.set('offerIdFromStock', offerId);
            newSearchParams.set('context', context);
            newSearchParams.set('clientId', clientId);
            if (partyId) {
                newSearchParams.set('partyId', partyId);
            }
            window.location.hash = '#/creator?' + newSearchParams.toString();
        })
            .catch(function (error) {
            setStatus('error');
            if (error.response.data.errorCode === 'stock-car-not-exists') {
                // przejście do starego kreatora
                if (stockCar.oldCreatorAllowed) {
                    var oldCreatorSearchParams = new URLSearchParams();
                    oldCreatorSearchParams.set('modelId', modelId);
                    oldCreatorSearchParams.set('commonOrderNumber', commonOrderNumber);
                    oldCreatorSearchParams.set('carOnLineDate', carOnLineDate);
                    oldCreatorSearchParams.set('structureWeek', structureWeek);
                    oldCreatorSearchParams.set('context', context);
                    oldCreatorSearchParams.set('clientId', clientId);
                    if (partyId) {
                        oldCreatorSearchParams.set('partyId', partyId);
                    }
                    window.location.href = '#/offer-wizard?' + oldCreatorSearchParams.toString();
                }
                else {
                    handleSetAppError({
                        redirect: 'errorPopup',
                        errorId: error.response.data.errorId ? error.response.data.errorId : '',
                        errorDesc: 'Brak zapisanej konfiguracji w systemie. Utwórz ofertę samodzielnie.',
                    });
                }
            }
            else {
                handleSetAppError({
                    redirect: 'errorPopup',
                    errorId: error.response.data.errorId ? error.response.data.errorId : '',
                    errorDesc: 'Nie można otworzyć wskazanej konfiguracji.',
                    retryHandler: handleOnSelectStockCar
                });
            }
        });
    }
    var currencyAmount = useFormattedCurrency(stockCar.listPrice ? stockCar.listPrice : 0);
    return (React.createElement("div", { className: " ".concat(isSelected && !searchingMode ? 'selectedStockCarListBodyWrapper' : 'stockCarsListBodyRowWrapper'), onClick: handleOnRowClick },
        React.createElement("div", { className: 'stockCarBaseData' },
            React.createElement("div", { className: 'stockCarListColumn stockCarListRadioColumn' }, searchingMode ?
                React.createElement(RadioGroup.Placeholder, null)
                : React.createElement(RadioGroup.Item, { value: stockCar.commonOrderNumber, id: stockCar.commonOrderNumber },
                    React.createElement(RadioGroup.Indicator, null))),
            React.createElement("div", { className: 'stockCarListColumn stockCarBaseColumn' },
                stockCar.reservation ? React.createElement("div", null,
                    React.createElement(ReservationChip, null)) : null,
                React.createElement("div", null,
                    React.createElement("p", null, stockCar.stockCategory ? stockCategoryDictionary(stockCar.stockCategory) : 'brak'),
                    React.createElement("p", null,
                        stockCar.modelId ? ModelDictionary.nameFor(stockCar.modelId) : 'brak',
                        " (",
                        stockCar.modelYear ? stockCar.modelYear : 'brak',
                        ")"),
                    React.createElement("p", null, stockCar.listPrice ? currencyAmount : 'brak'))),
            React.createElement("div", { className: 'stockCarListColumn' },
                React.createElement("p", null, stockCar.type ? stockTypeDictionary(stockCar.type) : 'brak'),
                React.createElement("p", null, stockCar.dealerOrder ? stockCar.dealerOrder : 'brak'),
                React.createElement("p", null, stockCar.vin ? stockCar.vin : 'brak')),
            React.createElement("div", { className: 'stockCarListColumn' },
                React.createElement("p", null, stockCar.deliveryDate ? stockCar.deliveryDate : 'brak')),
            React.createElement("div", { className: 'stockCarListColumn' },
                React.createElement("p", null, stockCar.lastDateForOrderChange ? stockCar.lastDateForOrderChange : 'brak')),
            React.createElement("div", { className: 'stockCarConfigurationColumn stockCarListColumn' },
                React.createElement("div", null,
                    React.createElement("ul", { className: 'stockCarConfigurationList' },
                        React.createElement("li", null, stockCar.drive ? stockCar.drive : 'brak'),
                        React.createElement("li", null, stockCar.version ? stockCar.version : 'brak'),
                        React.createElement("li", null, stockCar.upholstery ? stockCar.upholstery : 'brak'),
                        React.createElement("li", null, stockCar.interior ? stockCar.interior : 'brak'))))),
        isSelected ?
            React.createElement("div", { className: 'stockCarDetailedData' },
                React.createElement("div", { className: 'stockCarDetails' },
                    React.createElement("div", { className: 'stockCarDetailsRow' },
                        React.createElement("p", { className: 'stockCarDetailsLabel' }, "Dealer"),
                        React.createElement("p", null, stockCar.organizationName ? stockCar.organizationName : 'brak')),
                    React.createElement("div", { className: 'stockCarDetailsRow' },
                        React.createElement("p", { className: 'stockCarDetailsLabel' }, "Data produkcji"),
                        React.createElement("p", null, stockCar.productionDate ? stockCar.productionDate : 'brak')),
                    React.createElement("div", { className: 'stockCarDetailsRow' },
                        React.createElement("p", { className: 'stockCarDetailsLabel' }, "Nap\u0119d"),
                        React.createElement("p", null, stockCar.drive ? stockCar.drive : 'brak')),
                    React.createElement("div", { className: 'stockCarDetailsRow' },
                        React.createElement("p", { className: 'stockCarDetailsLabel' }, "Numer rejestracyjny"),
                        React.createElement("p", null, stockCar.registrationNumber ? stockCar.registrationNumber : 'brak'))),
                stockCar.comment ?
                    React.createElement("div", { className: 'stockCarDetailsCommentWrapper' },
                        React.createElement("p", { className: 'stockCarDetailsLabel' }, "Uwagi do konfiguracji:"),
                        React.createElement("p", null, stockCar.comment ? stockCar.comment : 'brak'))
                    : null,
                stockCar.warnings && stockCar.warnings.length > 0 ?
                    React.createElement("div", { className: 'stockCarDetailsWarningsWrapper' },
                        React.createElement("div", { className: 'stockCarDetailsWarnings' },
                            React.createElement("div", { className: 'stockCarWarningIcon' },
                                React.createElement(WarningIcon, null)),
                            React.createElement("div", { className: 'stockCarDetailsWarningsList' }, stockCar.warnings.map(function (warning, index) { return (React.createElement("p", { key: index }, warning)); }))))
                    : null,
                React.createElement("div", { className: 'stockCarDetailsButtonWrapper' },
                    React.createElement("div", { className: 'stockCarDetilsButton' },
                        React.createElement(Button, { disabled: status === 'pending', onClick: handleOnSelectStockCar }, "wybierz"))))
            : null));
}
